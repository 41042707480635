import "./App.css";

import SignupForm from "./SignupForm";

function SignupPage() {
	return (
		<div className="App">
			<header className="App-header">
				<h1>QPR Training in Denver</h1>
				<h2>Sunday January 28, 2024</h2>
				<h3>6-8:00 pm</h3>

				<p>Signing up helps us plan for the event.</p>
				<p>
					Your email will only be used to contact you in regards to a
					change in the event.
				</p>

				<SignupForm />
			</header>
		</div>
	);
}

export default SignupPage;
