import { useRouteLoaderData } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
	{ field: "name", headerName: "Name", width: 350 },
	{ field: "email", headerName: "Email", width: 350 },
	{ field: "number_of_guests", headerName: "# Attending", width: 100 },
];

const SignupListPage = () => {
	const signupList = useRouteLoaderData("signup-list");

	console.log("signuplist", signupList);
	const numberOfAttendees = signupList.reduce(
		(accumulator, currentValue) =>
			accumulator + currentValue.number_of_guests,
		0
	);

	return (
		<div
			id="signup-page"
			style={{ height: 600, width: 802, margin: "auto" }}
		>
			<h1>Attendees</h1>
			<div>Total Attendees: {numberOfAttendees}</div>
			<DataGrid rows={signupList} columns={columns} />
		</div>
	);
};
export default SignupListPage;
