import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";

const SignupMessage = ({ signupResult }) => {
	if (signupResult === 1)
		return <div id="success-message">Signup Successful</div>;
	if (signupResult === -1)
		return <div id="error-message">There was a problem signing you up</div>;
	return null;
};

const SignupForm = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const [signupResult, setSignupResult] = useState(0);

	const onSubmit = async (data) => {
		setSignupResult(0);

		try {
			const result = await axios({
				method: "post",
				url: "https://gx1hrv27ul.execute-api.us-east-2.amazonaws.com/signups",
				timeout: 10000, // 4 seconds timeout
				data: { ...data, timestamp: new Date() },
			});
			console.log("success", result);
			setSignupResult(1);
			reset();
		} catch (error) {
			console.error("error", error);
			setSignupResult(-1);
		}
	};

	return (
		<>
			<form id="signupform" onSubmit={handleSubmit(onSubmit)}>
				<label>Name</label>
				<input
					type="text"
					id="name"
					placeholder="Name"
					{...register("name")}
				/>

				<label>Email</label>
				<input
					type="text"
					id="email"
					placeholder="Email"
					{...register("email")}
				/>

				<label>Number Attending</label>
				<input
					type="text"
					id="number_of_guests"
					defaultValue={1}
					{...register("number_of_guests")}
				/>

				{/* errors will return when field validation fails  */}
				{errors.exampleRequired && <span>This field is required</span>}

				<input type="submit" />
			</form>
			<SignupMessage signupResult={signupResult} />
		</>
	);
};

export default SignupForm;
