import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import SignupPage from "./SignupPage";
import SignupListPage from "./SignupListPage";
import PageNotFound from "./PageNotFound";

const router = createBrowserRouter([
	{
		path: "/",
		element: <SignupPage />,
		errorElement: <PageNotFound />,
	},
	{
		path: "/signup-list",
		element: <SignupListPage />,
		loader: async () =>
			fetch(
				"https://gx1hrv27ul.execute-api.us-east-2.amazonaws.com/signups"
			),
		id: "signup-list",
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
